import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'  //时间戳转化为时间
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import VueLazyload from 'vue-lazyload'  //懒加载
// 全局注册vue一键复制内容到粘贴板
import Clipboard from 'clipboard'
Vue.prototype.Clipboard = Clipboard
Vue.use(VueLazyload)
// 配置项
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('./assets/images/default.png'),
    loading: require('./assets/images/loading.gif'),
    attempt: 1
})
//全局过滤器  转换时间
Vue.filter('dateFmt', (input, formatString = "YYYY-MM-DD") => {
    return moment(input).format(formatString)
})
Vue.use(animated)
Vue.use(ElementUI)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
    // to去哪  from  来自
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
//设置标题
Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
