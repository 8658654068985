<template>
    <div class="yz-pub-footer">
      <p>友情链接：<a class="link" target="_blank" href="https://www.hainayx.com/welcome/index.html">海纳易学</a>|<a class="link" target="_blank" href="https://www.brest-bs.com.cn/">布雷斯特高等商学院</a>|<a class="link" href="https://www.wdecloud.com/" target="_blank">伟东云教育集团</a></p>
      <p>版权所有  山东依智科技产业开发集团有限公司 ©2023
        <a href="//beian.miit.gov.cn/" target="_blank"> 鲁ICP备2020045516号-3</a>
        <img src="../assets/images/icp.jpg" class="icp-img"/>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37078602371056" rel="noreferrer" target="_blank">鲁公网安备37078602371056</a >
      </p>
    </div>
</template>
<script>
export default {
  data(){
    return{

    }
  }
}
</script>
<style lang="less">
   .yz-pub-footer{
      background-color: rgb(136, 130, 130);
      height: 80px;
      font-size: 16px;
      color: #fff;
      line-height: 30px;
      p{
        padding-top:6px;
      }
      a{
        color:#fff;
      }
      .link{
        margin:0px 5px 0px 5px;
      }
   }
   .icp-img{
     width: 20px;
     height: 20px;
     margin-left:10px;
     margin-right: 5px;
   }
   a:hover{
     color:#0184d2;
   }
</style>
