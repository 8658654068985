import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
    {
        path: '/live',
        name: 'live',
        component: () => import('../QrCodePage/enterLive.vue')
    },
    {
        path: '/focus',
        name: 'focus',
        component: () => import('../QrCodePage/focusPublic.vue')
    },
    {
        path: '/securityCont',
        name: 'securityCont',
        component: () => import('../views/securityTrain/securityCont.vue'),
        children: [
            {
                path: '/h5SecurityTrain',
                name: 'H5SecurityTrain',
                component: () => import('../views/securityTrain/h5/securityTrain.vue'), //手机端页面
                meta: {
                    title: '数据安全'
                }
            },
            {
                path: '/h5CdsoPage',
                name: 'H5CdsoPage',
                component: () => import('../views/securityTrain/h5/cdsoPage.vue'),//手机端页面
                meta: {
                    title: '注册数据安全官CDSO'
                }
            },
            {
                path: '/h5CdsePage',
                name: 'H5CdsePage',
                component: () => import('../views/securityTrain/h5/cdsePage.vue'),//手机端页面
                meta: {
                    title: '注册数据安全工程师CDSE'
                }
            },
            {
                path: '/h5DsmmPage',
                name: 'H5DsmmPage',
                component: () => import('../views/securityTrain/h5/dsmmPage.vue'),//手机端页面
                meta: {
                    title: 'DSMM测评师'
                }
            },
            {
                path: '/h5Advantage',
                name: 'H5Advantage',
                component: () => import('../views/securityTrain/h5/h5Advantage.vue'),
                meta: {
                    title: '一考双证'
                }
            },
            {
                path: '/h5About',
                name: 'H5About',
                component: () => import('../views/securityTrain/h5/h5About.vue'),
                meta: {
                    title: '国家级别'
                }
            },
            {
                path: '/h5ApprovePage',
                name: 'H5ApprovePage',
                component: () => import('../views/securityTrain/h5/h5ApprovePage.vue'),
                meta: {
                    title: '权威认证'
                }
            },
            {
                path: '/h5AssessPage',
                name: 'H5AssessPage',
                component: () => import('../views/securityTrain/h5/h5AssessPage.vue'),
                meta: {
                    title: '数据安全评估'
                }
            },
            {
                path: '/h5TeacherPage',
                name: 'H5TeacherPage',
                component: () => import('../views/securityTrain/h5/h5TeacherPage.vue'),
                meta: {
                    title: '顶级师资'
                }
            },
            {
                path: '/h5CoursePage',
                name: 'H5CoursePage',
                component: () => import('../views/securityTrain/h5/h5CoursePage.vue'),
                meta: {
                    title: '理实结合'
                }
            },
            {
                path: '/h5PayCostPage',
                name: 'h5PayCostPage',
                component: () => import('../views/securityTrain/h5/h5PayCostPage.vue'),
                meta: {
                    title: '报名缴费'
                }
            },
        ]
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/index',
        children: [
            {
                path: '/aboutUs',
                name: 'AboutUs',
                component: () => import('../views/aboutUs.vue')
            },
            {
                path: '/partners',
                name: 'Partners',
                component: () => import('../views/about/module/partners.vue')
            },
            {
                path: '/enterpriseHonor',
                name: 'EnterpriseHonor',
                component: () => import('../views/about/module/enterpriseHonor.vue')
            },
            {
                path: '/interResources',
                name: 'InterResources',
                component: () => import('../views/about/module/interResources.vue')
            },
            {
                path: '/goverCooperation',
                name: 'GoverCooperation',
                component: () => import('../views/cooperationMode/goverCooperation.vue')
            },
            {
                path: '/schoolCooperation',
                name: 'SchoolCooperation',
                component: () => import('../views/cooperationMode/schoolCooperation.vue')
            },
            {
                path: '/cooperationBenefits',
                name: 'CooperationBenefits',
                component: () => import('../views/cooperationMode/cooperationBenefits.vue')
            },
            {
                path: '/contactUs',
                name: 'ContactUs',
                component: () => import('../views/contactUs.vue')
            },
            {
                path: '/newsList',
                name: 'NewsList',
                component: () => import('../views/news/newsList.vue')
            },
            {
                path: '/newsDetails',
                name: 'NewsDetails',
                component: () => import('../views/news/newsDetails.vue')
            },
            {
                path: '/',
                name: 'Index',
                component: () => import('../views/index.vue')
            },
            {
                path: '/businessSystem',
                name: 'BusinessSystem',
                component: () => import('../views/businessAreas/overallSituation/businessSystem.vue')
            },
            {
                path: '/orgSystem',
                name: 'OrgSystem',
                component: () => import('../views/businessAreas/overallSituation/orgSystem.vue')
            },
            {
                path: '/abilityBuild',
                name: 'AbilityBuild',
                component: () => import('../views/businessAreas/overallSituation/abilityBuild.vue')
            },
            {
                path: '/businessFrame',
                name: 'BusinessFrame',
                component: () => import('../views/businessAreas/overallSituation/businessFrame.vue')
            },
            {
                path: '/techInnovation',
                name: 'TechInnovation',
                component: () => import('../views/businessAreas/techInnovation.vue')
            },
            {
                path: '/jobTrain',
                name: 'JobTrain',
                component: () => import('../views/businessAreas/jobTrain.vue')
            },
            {
                path: '/businessProfile',
                name: 'BusinessProfile',
                component: () => import('../views/businessAreas/collegeCoopera/businessProfile.vue')
            },
            {
                path: '/cooperMode',
                name: 'CooperMode',
                component: () => import('../views/businessAreas/collegeCoopera/cooperMode.vue')
            },
            {
                path: '/developmentProject',
                name: 'DevelopmentProject',
                component: () => import('../views/businessAreas/industrialDevelopment/developmentProject.vue')
            },
            {
                path: '/baseConstruction',
                name: 'BaseConstruction',
                component: () => import('../views/businessAreas/industrialDevelopment/baseConstruction.vue')
            },
            {
                path: '/overallPlan',
                name: 'OverallPlan',
                component: () => import('../views/businessAreas/industrialDevelopment/overallPlan.vue')
            },
            {
                path: '/bbsList',
                name: 'BbsList',
                component: () => import('../views/businessAreas/bbsList.vue')
            },
            {
                path: '/enterpriseProfile',
                name: 'EnterpriseProfile',
                component: () => import('../views/about/module/enterpriseProfile.vue')
            },
            {
                path: '/bbsCont',
                name: 'BbsCont',
                component: () => import('../views/bbs/bbscont.vue'),
                children: [
                    {
                        path: '/familyEduPage',
                        name: 'FamilyEduPage',
                        component: () => import('../views/bbs/generalRegulations/familyEduPage.vue')
                    },
                    {
                        path: '/courseBrochure',
                        name: 'CourseBrochure',
                        component: () => import('../views/bbs/brochure/courseBrochure.vue')
                    },
                ]
            },
            {
                path: '/securityCont',
                name: 'securityCont',
                component: () => import('../views/securityTrain/securityCont.vue'),
                children: [
                    {
                        path: '/securityTrain',
                        name: 'SecurityTrain',
                        component: () => import('../views/securityTrain/securityTrain.vue')
                    },
                    {
                        path: '/cdsoPage',
                        name: 'CdsoPage',
                        component: () => import('../views/securityTrain/cdsoPage.vue')
                    },
                    {
                        path: '/cdsePage',
                        name: 'CdsePage',
                        component: () => import('../views/securityTrain/cdsePage.vue')
                    },
                    {
                        path: '/dsmmPage',
                        name: 'DsmmPage',
                        component: () => import('../views/securityTrain/dsmmPage.vue')
                    },
                    {
                        path: '/advantage',
                        name: 'Advantage',
                        component: () => import('../views/securityTrain/advantage.vue')
                    },
                    {
                        path: '/about',
                        name: 'About',
                        component: () => import('../views/securityTrain/about.vue')
                    },
                    {
                        path: '/approvePage',
                        name: 'ApprovePage',
                        component: () => import('../views/securityTrain/approvePage.vue')
                    },
                    {
                        path: '/assessPage',
                        name: 'AssessPage',
                        component: () => import('../views/securityTrain/assessPage.vue'),
                    },
                    {
                        path: '/teacherPage',
                        name: 'TeacherPage',
                        component: () => import('../views/securityTrain/teacherPage.vue'),
                    },
                    {
                        path: '/coursePage',
                        name: 'CoursePage',
                        component: () => import('../views/securityTrain/coursePage.vue'),
                    },
                    {
                        path: '/payCostPage',
                        name: 'PayCostPage',
                        component: () => import('../views/securityTrain/payCost/payCostPage.vue'),
                    },

                ]
            }

        ]
    },
]
const router = new VueRouter({
    routes,
    mode: "history",
    // scrollBehavior(to, from, savedPosition) {
    //     return {x: 0, y: 0}
    // }
})
//不生效
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()

})
export default router
