<template>
  <div class="home goTop">
    <el-backtop target=".goTop">
    <i class="el-icon-caret-top"></i>
    </el-backtop>
    <pub-header></pub-header>
    <div class="common_box">
				<router-view/>
		</div>
    <pub-footer></pub-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import PubHeader from '@/components/pubHeader.vue'
import PubFooter from '@/components/pubFooter.vue'
export default {
  name: 'Home',
  components: {
    PubHeader,
    PubFooter
  }
}
</script>
<style  scoped>
  .goTop{
    height: 100vh;
    overflow-x: hidden;
  }
  >>>.el-backtop{
    background: #ccc !important;
  }
  .common_box{
    margin-top: 140px;
  }
</style>
