<template>
  <div class="yz-pub-warp">
    <div class="yz-logo-box">
      <div class="yz-logo">
        <img src="../assets/images/logo.png"/>
        <div class="top-right">
          <p class="t1">{{ showPhone == true ? '陆老师' : '全国热线' }}</p>
          <p>{{ showPhone == true ? '186-1273-3266' : '400 - 6357 - 585' }}</p>
        </div>
      </div>
    </div>
    <div class="yz-top">
      <div class="yz-box">
        <el-menu router mode="horizontal" class="el-menu-demo" :default-active="headerMenuActiveName"
                 background-color="#0184d2" text-color="#fff" active-text-color="#ffd04b">
          <div v-for="(item, index) in submenuList" :key="index" class="daohang-item">
            <!-- 一级菜单（没有任何子级菜单）-->
            <el-menu-item :index="item.path" v-if="!item.menuList">{{ item.title }}</el-menu-item>
            <!-- 一级菜单（有子级菜单）-->
            <el-submenu :index="item.path" v-else>
              <template slot="title">{{ item.title }}</template>
              <!-- 遍历二级菜单容器 -->
              <div v-for="(i, index) in item.menuList" :key="index">
                <!-- 判断二级菜单（没有三级菜单）-->
                <el-menu-item :index="i.path" v-if="!i.list">{{
                    i.title
                  }}
                </el-menu-item>
                <!-- 判断二级菜单（有三级菜单）-->
                <el-submenu :index="i.path" v-if="i.list">
                  <template slot="title">{{ i.title }}</template>
                  <el-menu-item
                      :index="j.path"
                      v-for="(j, index) in i.list"
                      :key="index"
                  >{{ j.title }}
                  </el-menu-item>
                </el-submenu>
              </div>
            </el-submenu>
          </div>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPhone: "", //手机号切换显示
      headerMenuActiveName: '/',
      submenuList: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "集团简介",
          path: "/about",
          menuList: [
            {
              title: "企业简介",
              path: "/enterpriseProfile",
            },
            {
              title: "合作伙伴",
              path: "/partners",
            },
            {
              title: "企业荣誉",
              path: "/enterpriseHonor",
            },
            {
              title: "  国际资源",
              path: "/interResources",
            },
          ],
        },
        {
          title: "证书培训",
          path: "/zhengshu",
          menuList: [
            {
              title: "家庭教育研修班",
              path: "/familyEduPage",
            },
            {
              title: "全媒体运营经理",
              path: "/courseBrochure",
            },
            {
              title: "数据安全培训",
              path: "/securityTrain",
            },
          ],
        },
        {
          title: "业务领域",
          path: "/yewu",
          menuList: [
            {
              title: "产教融合",
              path: "/businessFrame",
              // list: [
              //   {
              //     title: "业务框架",
              //     path: "/businessFrame",
              //   },
              //   {
              //     title: "组织体系",
              //     path: "/orgSystem",
              //   },
              //   {
              //     title: "能力建设",
              //     path: "/abilityBuild",
              //   },
              //   {
              //     title: "业务体系",
              //     path: "/businessSystem",
              //   },
              // ],
            },
            {
              title: "商学院",
              path: "/bbsList",
            },
            {
              title: "产业发展",
              path: "/developmentProject",
              // list: [
              //   {
              //     title: "整体规划",
              //     path: "/overallPlan",
              //   },
              //   {
              //     title: "发展项目",
              //     path: "/developmentProject",
              //   },
              //   {
              //     title: "基地建设",
              //     path: "/baseConstruction",
              //   },
              // ],
            },
            {
              title: "院校合作",
              path: "/cooperMode",
              // list: [
              //   {
              //     title: "业务概况",
              //     path: "/businessProfile",
              //   },
              //   {
              //     title: "合作模式",
              //     path: "/cooperMode",
              //   },
              // ],
            },
            {
              title: "在职培训",
              path: "/jobTrain",
            },
            {
              title: "科技创新",
              path: "/techInnovation",
            },
          ],
        },
        {
          title: "合作模式",
          path: "/hezuo",
          menuList: [
            {
              title: "政府合作模式",
              path: "/goverCooperation",
            },
            {
              title: "院校合作模式",
              path: "/schoolCooperation",
            },
            {
              title: "合作益处",
              path: "/cooperationBenefits",
            },
          ],
        },
        {
          title: "新闻资讯",
          path: "/newsList",
        },
        {
          title: "关于我们",
          path: "/contacts",
          menuList: [
            {
              title: "关于我们",
              path: "/aboutUs",
            },
            {
              title: "联系我们",
              path: "/contactUs",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getPath() {
      if (this.$route.path == '/securityTrain' || this.$route.path == '/cdsoPage' || this.$route.path == '/cdsePage' || this.$route.path == '/dsmmPage'
          || this.$route.path == '/advantage' || this.$route.path == '/about' || this.$route.path == '/approvePage' || this.$route.path == '/assessPage'
          || this.$route.path == '/teacherPage' || this.$route.path == '/coursePage'|| this.$route.path == '/payCostPage') {
        this.showPhone = true;
      } else {
        this.showPhone = false;
      }
    }
  },
  watch: {
    '$route': 'getPath'
  },
  mounted() {
    this.getPath();
    if (sessionStorage.getItem("headerMenuActiveName")) {
      this.headerMenuActiveName = sessionStorage.getItem("headerMenuActiveName");
    } else {
      this.headerMenuActiveName = '/'
    }
  }
};
</script>
<style lang="less">
.yz-pub-warp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  z-index: 1000;

}

.yz-logo-box {
  background: #FFFFFF;
  height: auto;
  width: 100%;
  .yz-logo {
    width: 1200px;
    height: 80px;
    margin: 0 auto;

    img {
      width: 160px;
      height: auto;
      float: left;
      margin-top: 17px;
    }

    .top-right {
      float: right;
      margin-top: 13px;

      p {
        font-size: 18px;
        line-height: 30px;
        color: #0184d2;
      }
    }
  }

}

.yz-top {
  height: 60px;
  width: 100%;
  background: #0184d2;
}

.yz-box {
  width: 1200px;
  margin: 0 auto;
}


.t1 {
  color: #333 !important;
}
</style>
<style scoped>
.daohang-item {
  float: left;
}

.yz-top >>> .el-menu.el-menu--horizontal {
  border: none;
}

.yz-top >>> .el-menu-item {
  color: #fff;
  font-size: 18px;
  height: 60px;
  padding: 0 30px;
}

.yz-top >>> .el-submenu__title {
  color: #fff;
  font-size: 18px !important;
  height: 60px;
  padding: 0 30px;
}

.yz-top >>> .el-icon-arrow-down:before {
  content: "";
}
</style>
