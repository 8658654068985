<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  watch: {
    $route(to, from) {
      // window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
}
</script>
<style lang="less">
@import "../static/css/pub.css";
@import "../static/css/home_index.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
